import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { StoryPoints, StoryPointScheme, StoryPointSchemeOwner } from 'daos/model_types';
import { StoryPointSchemesDao } from 'daos/story_point_schemes';
import { useStoryPointsSchemesRowData } from 'features/administration/settings/settings_page/story_point_schemes/schemes_sort';
import {
  StoryPointSchemeRowData,
  StoryPointSchemesWithStoryPoints,
} from 'features/administration/settings/settings_page/story_point_schemes/type';
import { getCurrentOrganizationId, getCurrentWorkspaceId } from 'features/common/current/selectors';
import { awaitRequestFinish } from 'lib/api';
import { convertSecondsToHours } from 'lib/helpers';

export const useStoryPointSchemesWithDefaultAndStoryPointValues = (
  itemId: number,
  isEditMode: boolean,
  canModifyStoryPoints: boolean
) => {
  const dispatch = useDispatch();
  const organizationId = useSelector(getCurrentOrganizationId);
  const workspaceId = useSelector(getCurrentWorkspaceId);

  const [storyPointSchemes, setStoryPointSchemes] = useState<ReadonlyArray<StoryPointScheme>>([]);
  const [schemeStoryPoints, setSchemeStoryPoints] = useState<ReadonlyArray<StoryPoints>>([]);
  const [storyPointSchemeOwners, setStoryPointSchemeOwners] = useState<ReadonlyArray<StoryPointSchemeOwner>>([]);
  const schemesWithDefaultData = useStoryPointsSchemesRowData(storyPointSchemes);

  const fetchStoryPointSchemes = useCallback(() => {
    const { uuid } = dispatch(
      StoryPointSchemesDao.fetchAll(
        { organizationId, workspaceId },
        { include: { includeStoryPoints: true, includeSchemeOwners: true } }
      )
    );

    dispatch(
      awaitRequestFinish<Array<StoryPointScheme>>(uuid, {
        onSuccess: ({ data, entities }) => {
          setStoryPointSchemes(data);
          setSchemeStoryPoints(Object.values(entities.storyPoints ?? {}));
          setStoryPointSchemeOwners(Object.values(entities.storyPointSchemeOwners ?? {}));
        },
      })
    );
  }, [dispatch, organizationId, workspaceId]);

  useEffect(() => {
    fetchStoryPointSchemes();
  }, [fetchStoryPointSchemes]);

  const storyPointsMap = schemeStoryPoints.reduce((acc: Record<string, Array<StoryPoints>>, storyPoint) => {
    const schemeId = storyPoint.storyPointScheme.id;
    if (!acc[schemeId]) {
      acc[schemeId] = [];
    }

    const modifiedStoryPoint = {
      ...storyPoint,
      value: Number(storyPoint.value),
      lowEffort: storyPoint.lowEffort ? convertSecondsToHours(storyPoint.lowEffort) : null,
      highEffort: storyPoint.highEffort ? convertSecondsToHours(storyPoint.highEffort) : null,
    };

    acc[schemeId]?.push(modifiedStoryPoint);
    return acc;
  }, {});

  const schemesWithStoryPoints = getSchemesWithStoryPoints(
    schemesWithDefaultData,
    storyPointsMap,
    storyPointSchemeOwners
  );

  const storyPointSchemeOwner = schemesWithStoryPoints.find((scheme) => {
    return scheme.storyPointSchemeOwners.some((owner) => owner.item?.id === itemId);
  });

  const currentStoryPointSchemeOwner =
    !canModifyStoryPoints && isEditMode && !storyPointSchemeOwner
      ? schemesWithStoryPoints.find((scheme) => scheme.default)
      : storyPointSchemeOwner;

  return {
    schemesWithStoryPoints,
    currentStoryPointSchemeOwner,
  };
};

const getSchemesWithStoryPoints = (
  schemesWithDefaultData: ReadonlyArray<StoryPointSchemeRowData>,
  storyPointsMap: Record<string, Array<StoryPoints>>,
  storyPointSchemeOwners: ReadonlyArray<StoryPointSchemeOwner>
): Array<StoryPointSchemesWithStoryPoints> => {
  return schemesWithDefaultData.map((scheme) => ({
    ...scheme,
    storyPoints: storyPointsMap[scheme.id]?.filter((point) => point.archivedAt === null) || [],
    storyPointSchemeOwners: storyPointSchemeOwners.filter((owner) => owner.storyPointScheme.id === scheme.id) || [],
  }));
};
