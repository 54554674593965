import { useSelector } from 'react-redux';

import { getStoryPointSchemeForProjectId } from 'redux/entities/selectors/story_point_schemes';

export const StoryPointSchemeName = ({ projectId }: { projectId: number }) => {
  const storyPointScheme = useSelector((state) => getStoryPointSchemeForProjectId(state, projectId));

  if (!storyPointScheme) {
    return null;
  }

  return <span className="story-point-scheme-name">[ {storyPointScheme.displayName} ]</span>;
};
