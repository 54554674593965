import classNames from 'classnames';
import { Label } from 'semantic-ui-react';

import { exclamationTriangleSolid, LpIcon } from 'features/common/lp_icon';
import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import { TabIcon } from 'features/jira_project/modal/sections/tab_icon';
import { TabNames } from 'features/jira_project/modal/types';
import { lpBrandWhite } from 'style/variables';

interface TabNameProps {
  name: TabNames;
  completed: boolean;
  dataTestId: string;
}

const TabName = ({ name, completed, dataTestId }: TabNameProps) => {
  const { isEditMode, hasFieldMappingWarning } = useJiraProjectModalContext();
  const shouldShowWarning = hasFieldMappingWarning && name === TabNames.FieldMapping;
  return (
    <span className="jira-project-modal__tab">
      <div className="jira-project-modal__tab-name" data-testid={dataTestId}>
        {!isEditMode && <TabIcon completed={completed} />}
        <span>{name}</span>
      </div>
      {shouldShowWarning && (
        <Label
          className={classNames('icon', 'notice', 'alert', 'jira-project-modal__tab-label-warning')}
          content={
            <LpIcon
              hoverText="Needs Attention: Incomplete Mapping"
              size="sm"
              icon={exclamationTriangleSolid}
              color={lpBrandWhite}
            />
          }
        />
      )}
    </span>
  );
};

export default TabName;
