import { timeStringInHoursFromTimeStringWithUnits } from 'lib/display_helpers/estimate_and_tracking_helpers';
import { hoursInputValidationRegEx } from 'lib/validation_helpers/hours_input_validation_regex';

import { EditableStoryPoints, EditableStoryPointsErrors } from './types';

const valueRegex = /^\d+(\.\d{1,2})?$/;

export const validateOne = (
  { id, value, lowEffort, highEffort }: EditableStoryPoints,
  formStoryPoints: ReadonlyArray<string | undefined>
): EditableStoryPoints => {
  const errors: EditableStoryPointsErrors = {};
  const storyPoints: EditableStoryPoints = { id, value, lowEffort, highEffort };

  if (!value || !valueRegex.test(value)) {
    errors.value = 'Story point value must be 0 or a positive decimal with not more than 2 numbers in fractional part';
  } else if (formStoryPoints.filter((formStoryPoint) => formStoryPoint === value).length > 1) {
    errors.value = 'Story point values must be unique';
  }

  if (lowEffort) {
    if (!hoursInputValidationRegEx.test(lowEffort)) {
      errors.lowEffort = `Low effort for story point ${value} must be a positive number followed by an optional 'h', 'd', or 'm'`;
    } else if (!highEffort) {
      errors.highEffort = 'If low effort is set, then high effort must be set';
    }
  }

  if (highEffort) {
    if (!hoursInputValidationRegEx.test(highEffort)) {
      errors.highEffort = `High effort for story point ${value} must be a positive number followed by an optional 'h', 'd', or 'm'`;
    } else if (!lowEffort) {
      errors.lowEffort = 'If high effort is set, then low effort must be set';
    } else if (
      lowEffort &&
      Number(timeStringInHoursFromTimeStringWithUnits(highEffort)) <
        Number(timeStringInHoursFromTimeStringWithUnits(lowEffort))
    ) {
      errors.highEffort = 'High effort must be greater than or equal to low effort';
    }
  }

  if (errors.value || errors.lowEffort || errors.highEffort) {
    storyPoints.errors = errors;
  }

  return storyPoints;
};

const validate = (storyPoints: ReadonlyArray<EditableStoryPoints>): ReadonlyArray<EditableStoryPoints> => {
  const storyPointValues = storyPoints.map(({ value }) => value);
  return storyPoints.map((entry) => validateOne(entry, storyPointValues));
};

export const isValid = (storyPoints: ReadonlyArray<EditableStoryPoints>): boolean => {
  return validate(storyPoints).every(({ errors }) => !errors);
};
