import { keyBy } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { createSelector } from 'reselect';

import { StandardColumns } from 'containers/shared/custom_column/enum';
import { SystemSettings } from 'daos/model_types';
import { Currency, OrganizationFeatureFlags, ColumnsSelection } from 'daos/types';
import { FeatureFlag } from 'lib/feature_flags';
import { readonlyArray } from 'lib/readonly_record';
import { createCacheByIdConfig } from 'redux/entities/selectors/shared';
import { RootState } from 'redux/root_reducer';

const getSystemSettings = (state: RootState, workspaceId = 0): SystemSettings | undefined => {
  return state.entities.systemSettings[workspaceId];
};

// Intentionally using StandardColumnOptions for these because widget columns must correspond with the server-side enum
const emptyColumnsArray = readonlyArray<StandardColumns>([]);
const emptyTimezoneArray = readonlyArray<string>([]);
const emptyCurrenciesArray = readonlyArray<Currency>([]);

export const allFeatureFlagsDisabled = Object.fromEntries(
  Object.values(FeatureFlag).map((key) => [key, false])
) as OrganizationFeatureFlags;

export const getSystemFeatureFlagDefaults = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.featureFlagDefaults ?? allFeatureFlagsDisabled;
});

export const getSystemTimezones = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.timezones ?? emptyTimezoneArray;
});

export const getSystemCurrencies = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.currencies ?? emptyCurrenciesArray;
});

const getSystemCurrenciesByCurrencyCode = createSelector(getSystemCurrencies, (systemCurrencies) => {
  return keyBy<Currency>(systemCurrencies, (currency) => currency.currencyCode);
});

export const getSystemCurrencyForCurrencyCode = (state: RootState, currencyCode: string) =>
  getSystemCurrenciesByCurrencyCode(state)[currencyCode];

export const getIsSlackIntegrationSupportedByEnvironment = createSelector(getSystemSettings, (systemSettings) => {
  return !!systemSettings?.allowSlackIntegration;
});

export const getEmailRegexValidator = createSelector(getSystemSettings, (systemSettings) => {
  return systemSettings?.emailRegex ?? '';
});

export const getMetricsWidgetColumnsExceptTaskStatus = createSelector(getSystemSettings, (systemSettings) => {
  if (!systemSettings) {
    return emptyColumnsArray;
  } else {
    return readonlyArray(
      systemSettings.columns.metricsWidget.filter((column) => column !== StandardColumns.TaskStatus)
    );
  }
});

export const getMetricsWidgetDefaultColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.metricsWidgetDefaults ?? emptyColumnsArray;
};

export const getPropertiesWidgetColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.propertiesWidget ?? emptyColumnsArray;
};
export const getPeopleReportWidgetColumns = (state: RootState, workspaceId = 0) => {
  return getSystemSettings(state, workspaceId)?.columns.peopleReportWidget ?? emptyColumnsArray;
};

export const getPropertiesWidgetDefaultColumns = (state: RootState) => {
  return getSystemSettings(state)?.columns.propertiesWidgetDefaults ?? emptyColumnsArray;
};

export const getIterationTableDefaultColumns = createSelector(getSystemSettings, (systemSettings) => {
  return (
    systemSettings?.columns.iterationTableDefaults ??
    readonlyArray([StandardColumns.TotalWorkRange, StandardColumns.ExpectedStart, StandardColumns.ExpectedFinish])
  );
});

export const getListWidgetsColumns = createCachedSelector(
  (state: RootState, _: keyof ColumnsSelection, workspaceId = 0) => getSystemSettings(state, workspaceId),
  (_: RootState, key: keyof ColumnsSelection, __: number) => key,
  (systemSettings, key) => {
    if (!systemSettings) {
      return emptyColumnsArray;
    } else {
      return systemSettings.columns.listWidgets[key];
    }
  }
)(createCacheByIdConfig());

export const getPppsColumns = createCachedSelector(
  (state: RootState, _: keyof ColumnsSelection, workspaceId = 0) => getSystemSettings(state, workspaceId),
  (_: RootState, key: keyof ColumnsSelection, __: number) => key,
  (systemSettings, key) => {
    if (!systemSettings) {
      return emptyColumnsArray;
    } else {
      return systemSettings.columns.ppp[key];
    }
  }
)(createCacheByIdConfig());
