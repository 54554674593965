export enum SourceSystem {
  JIRA = 'jira',
  ROADMUNK = 'roadmunk',
}

export enum ConnectedStatus {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
}

export enum ExternalIntegrationStatusValues {
  INITIALIZED = 'INITIALIZED',
  ERROR = 'ERROR',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE_WITH_ERROR = 'COMPLETE_WITH_ERROR',
}

export enum SyncProjectJobErrorType {
  PROJECT_NOT_FOUND = 'PROJECT_NOT_FOUND',
  INVALID_AUTHENTICATION = 'INVALID_AUTHENTICATION',
  OVERALL_SYNC = 'OVERALL_SYNC',
  RESOURCE_LIMIT_EXCEEDED = 'RESOURCE_LIMIT_EXCEEDED',
  SET_CUSTOM_FIELD_VALUE = 'SET_CUSTOM_FIELD_VALUE',
  CONFIGURE_STATUSES = 'CONFIGURE_STATUSES',
  CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT',
  CREATE_TASK = 'CREATE_TASK',
  CONFIGURE_CUSTOM_FIELD = 'CONFIGURE_CUSTOM_FIELD',
  ITEM_ISSUE_SYNC = 'ITEM_ISSUE_SYNC',
  UPDATE_TASK = 'UPDATE_TASK',
  UPDATE_JIRA_STATUS = 'UPDATE_JIRA_STATUS',
  PROJECT_USER_SYNC = 'PROJECT_USER_SYNC',
  UNLINKED_ITEM = 'UNLINKED_ITEM',
  SUBTASK_ISSUE_SYNC = 'SUBTASK_ISSUE_SYNC',
  ITEM_ISSUE_PUSH = 'ITEM_ISSUE_PUSH',
  UPDATE_ASSIGNMENT = 'UPDATE_ASSIGNMENT',
  DUPLICATE_TASK = 'DUPLICATE_TASK',
  PRIORITY_NOT_FOUND_IN_JIRA = 'PRIORITY_NOT_FOUND_IN_JIRA',
  ISSUE_RANK_PUSH = 'ISSUE_RANK_PUSH',
  FIELD_NOT_SETTABLE_IN_JIRA = 'FIELD_NOT_SETTABLE_IN_JIRA',
  WORKLOG_SYNC_COST_CODE_ERROR = 'WORKLOG_SYNC_COST_CODE_ERROR',
  WORKLOG_SYNC_ERROR = 'WORKLOG_SYNC_ERROR',
  ASSIGNEE_NOT_FOUND_IN_JIRA = 'ASSIGNEE_NOT_FOUND_IN_JIRA',
  PROJECT_LEAD_INACTIVE = 'PROJECT_LEAD_INACTIVE',
  INVALID_MAPPING_FIELD_VALUE = 'INVALID_MAPPING_FIELD_VALUE',
  TARGET_START_AFTER_FINISH = 'TARGET_START_AFTER_FINISH',
  STORY_POINT_FIELD_NOT_FOUND = 'STORY_POINT_FIELD_NOT_FOUND',
  CREATE_ISSUE_PERMISSION_MISSING = 'CREATE_ISSUE_PERMISSION_MISSING',
  UNKNOWN_PUSH_ERROR = 'UNKNOWN_PUSH_ERROR',
}

export enum TaskGroupHierarchy {
  GroupFlatLevel = 'GROUP_FLAT_LEVEL',
  GroupByEpic = 'GROUP_BY_EPIC',
  GroupByParentAncestry = 'GROUP_BY_PARENT_ANCESTRY',
}

export enum EstimationMappingType {
  StoryPoints = 'storyPoints',
  RemainingTime = 'remainingTime',
}

export enum EstimationType {
  StoryPoints = 'STORY_POINTS',
  RemainingTime = 'REMAINING_ESTIMATE',
}

export enum StoryPointSchemeActions {
  CreateNew = 'CREATE_NEW',
  UseExisting = 'USE_EXISTING',
  UseDefault = 'USE_DEFAULT',
}

export enum TaskPushSetting {
  Manual = 'MANUAL',
  Off = 'OFF',
}
