import { useFormikContext } from 'formik';
import { useState } from 'react';

import { useJiraProjectModalContext } from 'features/jira_project/modal/jira_project_modal_context';
import {
  isDefaultStoryPointScheme,
  isValidScheme,
} from 'features/jira_project/modal/sections/estimate_mapping_section/helpers';
import { InvalidSchemeMessage } from 'features/jira_project/modal/sections/estimate_mapping_section/invalid_scheme_message';
import { StoryPointsSchemeChoice } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_choice';
import { StoryPointsSchemeEditableContent } from 'features/jira_project/modal/sections/estimate_mapping_section/story_points_scheme_editable_content';
import {
  JiraProjectModalFormFields,
  JiraProjectModalFormValues,
  SchemeDisplayNames,
} from 'features/jira_project/modal/types';

export const StoryPointsScheme = () => {
  const {
    selectedSchemeActionDisplayName,
    setSelectedSchemeActionDisplayName,
    canModifyStoryPoints,
    isEditMode,
    schemesWithStoryPoints,
  } = useJiraProjectModalContext();
  const [isValidSelectedScheme, setIsValidSelectedScheme] = useState(true);
  const { values } = useFormikContext<JiraProjectModalFormValues>();
  const selectedExistingSchemeToCopyValue = values[JiraProjectModalFormFields.SelectedExistingSchemeToCopy];
  const existingSchemeToUse = values[JiraProjectModalFormFields.ExistingSchemeId];
  const isDefaultScheme = isDefaultStoryPointScheme(schemesWithStoryPoints, existingSchemeToUse);
  const isReadOnly = !canModifyStoryPoints || !!existingSchemeToUse;
  const shouldShowSchemeEditableContent =
    selectedSchemeActionDisplayName === SchemeDisplayNames.CreateNewScheme ||
    !!selectedExistingSchemeToCopyValue ||
    !!existingSchemeToUse;
  const selectedSchemeWithStoryPoints = schemesWithStoryPoints.find((scheme) => scheme.id === existingSchemeToUse);
  const selectedSchemeWithStoryPointsIsValid =
    !isEditMode && selectedSchemeWithStoryPoints ? isValidScheme(selectedSchemeWithStoryPoints) : true;

  return (
    <>
      <StoryPointsSchemeChoice
        schemesWithStoryPoints={schemesWithStoryPoints}
        selectedSchemeActionDisplayName={selectedSchemeActionDisplayName}
        setSelectedSchemeActionDisplayName={setSelectedSchemeActionDisplayName}
        setIsValidSelectedScheme={setIsValidSelectedScheme}
      />
      {!isValidSelectedScheme || !selectedSchemeWithStoryPointsIsValid ? <InvalidSchemeMessage /> : null}
      {shouldShowSchemeEditableContent && (
        <StoryPointsSchemeEditableContent isReadOnly={isReadOnly} isDefaultScheme={isDefaultScheme} />
      )}
    </>
  );
};
